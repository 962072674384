import React from "react"
import useTranslations from "../../../utils/useTranslations"

const TokenAirdrop = ({ pageContext: {text} }) => {
const t = useTranslations(text)
  

  return (
    <div className="container my-5 pt-5">
      <h1>{t("BitTorrent Token (BTT) Airdrop")}</h1>
      <hr style={{ margin: `2rem 0` }} />
    </div>
  )
}

export default TokenAirdrop